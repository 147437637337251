import React from "react";
import {useQuery} from "@apollo/client";

import FETCH_PORTFOLIO_PAGE from "../../api/Portfolio.service";
import styles from './ProjectDetailsBlock.module.css';
import InfoBlock from "../info-block/InfoBlock";
import BlockHeader from "../block-header/BlockHeader";
import DottedItemList from "../dotted-item-list/DottedItemList";
import CustomSlider from "../custom-slider/CustomSlider";

import img from '../../images/project-details/project-details.png';
import environment from "../../environments/environment";

const header = {
    title: 'Project name'
};

const ProjectDetailsBlock = () => {
  const {loading, error, data} = useQuery(FETCH_PORTFOLIO_PAGE);
  const isBrowser = () => typeof window !== "undefined"

  const thoseId = isBrowser() && (window.location.href).slice((window.location.href).lastIndexOf('/')+1);
  let project;
  if (data) {
    project = data.portfolioPage.projects.find(proj => proj.id === thoseId);
  }
  const keyFeaturesTitle = 'Key Features';
  const usedTechnologiesTitle = 'Used technologies';

  const toActiveItem = (title, description) => ({
    title,
    description
  });

  return (
      <>
          {project &&
              <div className={ styles.project }>
                  <div className={ styles.header }>
                    <BlockHeader header={ project } />
                  </div>

                  <div className={styles.generalInfo}>
                    <div className={styles.infoBlock}>
                      <InfoBlock activeItem={project} options={{ isTitle: true, isDivider: true }}/>
                    </div>

                    <div className={styles.imageInfo}>
                      {project.mainImage && <img src={`${environment.apiUrl}${project.mainImage.url}`} alt=""/>}
                      {project.projectUrl && <div className={styles.webLink}>
                        <a href={project.projectUrl} className='accent-text'>WEB</a>
                        <div className={styles.webIcon}/>
                      </div>}
                    </div>
                  </div>

                  <div className={styles.secondaryInfo}>
                    <div className={styles.infoText}>
                      {project.ChallengesAndProblems
                        ? <InfoBlock activeItem={toActiveItem(project.ChallengesAndProblems.title, project.ChallengesAndProblems.description)} options={{ isTitle: true, isDivider: true }} />
                        : <span></span>
                      }
                    </div>

                    <div className={styles.infoText}>
                      {project.OurSolutions
                        ? <InfoBlock activeItem={toActiveItem(project.OurSolutions.title, project.OurSolutions.description)} options={{ isTitle: true, isDivider: true }} />
                        : <span></span>
                      }
                    </div>
                  </div>

                  <div className={styles.sliderWrapper}>
                    <CustomSlider imgList={project.screenshots} />
                  </div>

                <div className={styles.dottedListInfo}>
                  {project.keyFeatures.length > 0 && <div className={styles.features}>
                    <DottedItemList dottedItems={project.keyFeatures}
                                    title={keyFeaturesTitle}
                                    isPink={false} />
                  </div>}
                  {project.technologyAreas.length > 0 && <div className={styles.technologies}>
                    <DottedItemList dottedItems={project.technologyAreas}
                                    title={usedTechnologiesTitle}
                                    isPink={true} />
                  </div>}
                  </div>
                </div>
            }
        </>
    )
};

export default ProjectDetailsBlock;
