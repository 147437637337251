import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import QuestionsFormBlock from "../components/question-form-block/QuestionsFormBlock";
import ProjectDetailsBlock from "../components/project-details/ProjectDetailsBlock";

const ProjectDetails = () => (
    <Layout>
        <SEO title="Project Details Page" />

        <div className='container'>
            <ProjectDetailsBlock />
        </div>
        {/*<div className='container'>*/}
        {/*    <QuestionsFormBlock />*/}
        {/*</div>*/}
    </Layout>
);

export default ProjectDetails;
